import {readable} from 'svelte/store';

/**
 * Svelte Media Query Store
 * @param mediaQuery
 * @returns {Readable<unknown>}
 */
export const mediaQueryStore = mediaQuery => readable(undefined, set => {
    const mql = window.matchMedia(mediaQuery);
    const onchange = () => set(mql.matches);

    // Init
    onchange();

    // Listen for changes
    mql.addEventListener('change', onchange);

    // Return an unsubscribe function
    return () => mql.removeEventListener('change', onchange);
});
