$(function() {
  /*
   * #kontakt
   */
  const form = $('#kontakt-form');
  const message = form.find('[name*="[message]"]');
  const submitButtons = form.find('[type="submit"]');

  function showDialog() {
    $('#kontakt-dialog-title').html('Ihre Kontaktanfrage wird gesendet...');
    $('#kontakt-dialog-processing').show();
    $('#kontakt-dialog-message').hide();
    $('#kontakt-dialog-footer').hide();
    $('#kontakt-dialog-dismiss').hide();
    $('#kontakt-dialog').modal();
  }

  function setResult(message, title) {
    title = title || 'Ihre Kontaktanfrage wurde gesendet';
    $('#kontakt-dialog-title').html(title);
    $('#kontakt-dialog-message').html(message);
    $('#kontakt-dialog-message').show();
    $('#kontakt-dialog-footer').show();
    $('#kontakt-dialog-dismiss').show();
    $('#kontakt-dialog-processing').hide();
  }

  function enableSubmitButtons() {
    submitButtons.removeAttr('disabled');
  }

  function disableSubmitButtons() {
    submitButtons.attr('disabled', 'disabled');
  }

  function formHandler(e) {
    // prevent default form submission
    e.preventDefault();

    // prevent empty message submission
    if (message.val().length === 0) {
      return;
    }

    disableSubmitButtons();
    showDialog();

    // ajax form submission
    $.post(form.attr('action'), form.serialize(), null, 'html')
      .done(function(data) {
        enableSubmitButtons();
        setResult(data);
      })
      .fail(function(jqXHR) {
        enableSubmitButtons();
        $('body,html').empty().html(jqXHR.responseText);
      });
  }

  form
    .bootstrapValidator()
    .on('error.field.bv', '[name*="[name]"],[name*="[email]"]',
      function(e, data) {
        if (data.element.data('bv.result.emailAddress') !== 'INVALID') {
          data.element.closest('.form-group')
            .removeClass('has-error')
            .addClass('has-warning');
          enableSubmitButtons();
        }
      }
    )
    .on('success.field.bv', enableSubmitButtons)
    .on('success.field.bv', '[name*="[name]"],[name*="[email]"]',
      function(e, data) {
        data.element.closest('.form-group')
        .removeClass('has-error has-warning');
      }
    )
    .on('error.form.bv', formHandler)
    .on('success.form.bv', formHandler);
});
