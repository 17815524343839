import {computePosition, offset, arrow, shift, autoUpdate} from '@floating-ui/dom';

const tooltip = (referenceElement, popoverElement, arrowElement) => {

  const updatePosition = () => {
    computePosition(referenceElement, popoverElement, {
      placement: 'top',
      middleware: [
        offset(10),
        shift(),
        arrow({element: arrowElement}),
      ],
    }).then(({x, y, middlewareData}) => {
      popoverElement.style.left = `${x}px`;
      popoverElement.style.top = `${y}px`;

      if (middlewareData.arrow) {
        const {x, y} = middlewareData.arrow;
        arrowElement.style.left = x != null ? `${x}px` : '';
        arrowElement.style.top = y != null ? `-${y}px` : '';
      }
    });
  };

  let stopAutoUpdates = null;
  const show = () => {
    popoverElement.style.display = 'block';
    stopAutoUpdates = autoUpdate(referenceElement, popoverElement, updatePosition);
  };

  const hide = () => {
    popoverElement.style.display = '';
    stopAutoUpdates();
  };

  [
    ['mouseenter', show],
    ['mouseleave', hide],
    ['focus', show],
    ['blur', hide],
  ].forEach(([event, listener]) => {
    referenceElement.addEventListener(event, listener);
  });
};


const tooltipElements = document.querySelectorAll('.buchungszeiten-tooltip');
for (let i = 0; i < tooltipElements.length; i++) {
  const tooltipElement = tooltipElements[i];
  const referenceElement = tooltipElement.querySelector('.buchungszeiten-tooltip-reference');
  const popoverElement = tooltipElement.querySelector('.buchungszeiten-tooltip-popover');
  const arrowElement = tooltipElement.querySelector('.buchungszeiten-tooltip-popover-arrow');

  tooltip(referenceElement, popoverElement, arrowElement);
}
