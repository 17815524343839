// https://vitejs.dev/guide/backend-integration.html
import 'vite/modulepreload-polyfill';

import RaumCarousel from "./components/RaumCarousel.svelte";

import './globals.js';
import 'bootstrap';

import './components/buchungszeiten.js';
import './components/kontakt.js';

import 'openvalidator';

const raumCarouselDiv = document.querySelector("#raum-carousel")
if (raumCarouselDiv) {
    new RaumCarousel({
        target: raumCarouselDiv,
    });
}
